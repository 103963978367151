import Colors from '@root/brand/src/utils/colors';
import MylesColors from '@root/trymyles.com/src/utils/myles-colors.js';
import MylesLogo from '@root/trymyles.com/src/components/myles-logo';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Seo from '@root/trymyles.com/src/components/seo';
import normalizeStyles from '@root/joinroot.com/src/components/normalize.css';
import { StyleSheet } from '@root/core/src/utils/styles';
import { injectGlobal } from '@root/vendor/emotion';
import { light, regular, semiBold, semiBoldItalic } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default () => (
  <>
    <Seo
      author={'Root Insurance Co.'}
      description={'The Myles roadside assistance app lets you earn free roadside service, just for driving. With a few taps, you can request the service you need and receive driver updates, so you know when help is on the way. And you can get roadside service as soon as you download the app - no membership required.'}
      title={'Myles Terms and Conditions'}
    />
    <div css={styles.container}>
      <section css={styles.sectionHeader}>
        <MylesLogo css={styles.logo} />
        <h1 css={styles.heading1}>Terms and Conditions</h1>
        <h1 css={styles.heading2}>For trymyles.com</h1>
        <h6 css={styles.heading6}>Last updated on June 18, 2019</h6>
      </section>
      <section css={styles.copy}>
        <p>
          <span>Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the </span>
          <a
            css={styles.referenceTo}
            href={'/'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >TryMyles.com
          </a>
          <span> website (“Website”, “Site”) operated by Root, Inc dba Myles (“Myles”, “us”, “we”, or “our”).</span>
        </p>
        <p>
          <span>Your access to use the Site is conditioned on your acceptance of and compliance with these Terms. These terms apply to all </span>
          <a
            css={styles.referenceTo}
            href={'/'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >TryMyles.com
          </a>
          <span> visitors, users, and others who wish to use the Site.
          </span>
        </p>
        <p>
          <span>By accessing or using the Site you agree to be bound by these Terms. If you disagree with any part of the Terms you are not granted permission to access or otherwise use the Site. These terms apply only to your use of </span>
          <a
            css={styles.referenceTo}
            href={'/'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >TryMyles.com
          </a>
          <span> and do not change or modify any other agreement of contract between you and Myles.</span>
        </p>
        <p>
          <span>
            Myles reserves the right, in its sole discretion, to change these Terms and
            Conditions at any time without prior notice to you. Your continued use of the
            Site after any change may be made to the Terms constitutes acceptance of
            the change or changes.
          </span>
        </p>
        <p>
          <span>
            To the extent permitted by law, Myles disclaims all warranties, express or
            implied, including, but not limited to, implied warranties of merchantability and
            fitness for a particular purpose. Myles does not warrant that the functionality
            or operation of the Site will be uninterrupted or free from error, that any
            defects in the Site will be corrected, or that the Site or the server(s) that make
            it available are free of viruses or other harmful conditions or components.
          </span>
        </p>
        <p>
          <span>
            If you access or use the Myles App (“App”) from or through
          </span>
          <a
            css={styles.referenceTo}
            href={'/'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          > TryMyles.com
          </a>
          <span> or in any other manner, access and use of the App is subject to acceptance of the terms of Myles’s </span>
          <a
            css={styles.referenceTo}
            href={'/app-eula'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >Mobile Application End User license Agreement.
          </a>
        </p>
        <h2 css={styles.paraHeader}>Privacy Policy</h2>
        <p>
          <span>The Privacy Policy for </span>
          <a
            css={styles.referenceTo}
            href={'/'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >TryMyles.com
          </a>
          <span> can be accessed
            <a
              css={styles.referenceTo}
              href={'/privacy-policy'}
              rel={'noopener noreferrer'}
              target={'_blank'}
            > here.
            </a> The Privacy
            Policy discloses Myles’s privacy practices and will notify you of the following
            regarding your use of
          </span>
          <a
            css={styles.referenceTo}
            href={'/'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          > TryMyles.com.
          </a>
        </p>
        <ul>
          <li>
            <p>What information and/or other information is collected from you, how it
              is used and with whom it may be shared.
            </p>
          </li>
          <li>
            <p>
              What choices are available to you regarding the use of your information.
            </p>
          </li>
          <li>
            <p>
              The security procedures Myles has in place to protect your information.
            </p>
          </li>
          <li>
            <p>
              How you can correct any inaccuracies in collected information.
            </p>
          </li>
        </ul>

        <p>
          <span css={styles.boldedText}>Marketing Communications.</span>
          <span> By using </span>
          <a
            css={styles.referenceTo}
            href={'/'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >TryMyles.com
          </a>
          <span> you agree that Myles can contact you with email or other communications relating to the Site. You can contact us at </span>
          <a
            css={styles.referenceTo}
            href={'mailto:trymyles@joinroot.com'}
          >trymyles@joinroot.com
          </a>
          <span> to request that we remove you from our marketing distribution lists.</span>
        </p>
        <p>
          <span>We reserve the right to refuse service, remove or edit content in Myles’s sole discretion.</span>
        </p>
        <h2 css={styles.paraHeader}>Links to other websites</h2>
        <p>
          <span>Our Site may contain links to third party websites or services that are not owned or controlled by Myles. Myles has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</span>
        </p>
        <p>
          <span>You acknowledge and agree that Myles shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third-party websites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.
          </span>
        </p>
        <h2 css={styles.paraHeader}>Governing law</h2>
        <p>In general, these Terms shall be governed and construed in accordance with
          the laws of Ohio, United States, without regard to its conflict of law provisions.
        </p>
        <h2 css={styles.paraHeader2}>Specific enforcement of rights</h2>
        <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Site, and supersede and replace any prior agreements we might have had between us regarding the Site.
        </p>
        <h2 css={styles.paraHeader2}>Termination</h2>
        <p>
          <span>We may terminate or suspend your account and bar access to the Site immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms. Please note that termination described in this section pertains to your use of </span>
          <a
            css={styles.referenceTo}
            href={'/'}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >TryMyles.com.
          </a>
        </p>
        <p>
          <span>If you wish to terminate your use of the Site, you may simply discontinue using the Site.
          </span>
        </p>
        <p>
          <span>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
          </span>
        </p>
        <p>Form 11, June 2019</p>
      </section>
    </div>
  </>
);

const styles = StyleSheet.create({
  container: {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: Colors.white(),
    marginBottom: '5em',
  },
  sectionHeader: {
    textAlign: 'center',
    ...regular(),
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '3.75em 2em 0 2em',
    ...Responsive.lg({
      maxWidth: '720px',
    }),
    svg: {
      marginTop: '20px',
      fill: MylesColors.mylesGreen(),
      stroke: MylesColors.mylesGreen(),
      transition: 'all 250ms cubic-bezier(0.37, 0, 0.28, 1)',
      width: '30vw',
    },
  },
  heading1: {
    margin: '1em 0 0 0',
    fontSize: '3em',
    lineHeight: 1.25,
    color: Colors.gray60(),
    ...Responsive.lessThanSm({
      fontSize: '2em',
      lineHeight: 1.1,
    }),
  },
  heading2: {
    margin: '1em 0 0 0',
    fontSize: '2em',
    lineHeight: 1.1,
    color: Colors.gray60(),
    ...Responsive.lessThanSm({
      fontSize: '1em',
      lineHeight: 1.1,
    }),
  },
  heading6: {
    margin: '2px 0 0 0',
    fontSize: '1em',
    lineHeight: 1.5,
    color: Colors.gray50(),
    ...regular(),
  },
  boldedText: {
    ...semiBold(),
    color: Colors.gray60(),
  },
  copy: {
    padding: '0.75em 2em 0 2em',
    ...light(),
    fontSize: '1.2em',
    lineHeight: 1.5,
    color: Colors.gray60(),
    marginRight: 'auto',
    marginLeft: 'auto',
    ...Responsive.lg({
      maxWidth: '720px',
    }),
    p: {
      ...light(),
      marginTop: '1em',
      marginBottom: 0,
      fontSize: '1em',
      lineHeight: 1.5,
      color: Colors.gray60(),
      textAlign: 'justify',
    },
    ul: {
      listStyleType: 'circle',
      margin: '0.5em 0 0 0',
      padding: '0 0 0 2em',
      color: Colors.gray40(),
    },
  },
  referenceTo: {
    ...regular(),
    ':link': {
      color: MylesColors.mylesGreen(),
      textDecoration: 'none',
    },
    ':active': {
      color: Colors.DEPRECATED_purple(),
      textDecoration: 'underline',
    },
    ':hover': {
      color: Colors.DEPRECATED_purple(),
      textDecoration: 'none',
    },
    ':visited': {
      color: Colors.DEPRECATED_green(),
      textDecoration: 'none',
    },
  },
  paraHeader: {
    fontSize: '1.2em',
    lineHeight: 1.5,
    textTransform: 'uppercase',
    color: Colors.gray60(),
    marginTop: '2em',
    marginBottom: 0,
  },
  paraHeader2: {
    fontSize: '1em',
    lineHeight: 1.5,
    color: Colors.gray60(),
    marginTop: '1em',
    marginBottom: '0.5em',
    ...semiBoldItalic(),
  },
});

injectGlobal(normalizeStyles);
